import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ArticleListing from "../components/article-listing";

import "../styles/index.css";
import indexStyles from "./index.module.css";

function getArticleListing(article) {
  const title = article.title || article.slug;
  const rawSlug = article.slug;
  const category = article.category;

  let slug =
    "/" +
    category.replace(/_/g, "-").replace(/([A-Z])/g, v => {
      return v.toLowerCase();
    }) +
    `/${rawSlug}/`;

  return <ArticleListing title={title} slug={slug} key={slug}/>;
}

class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const childArticles = data.cms.articles;

    console.log(childArticles);

    let articles = {
      gettingStarted: [],
      usingMailpanion: [],
      accountManagement: [],
      dataSecurity: [],
      additionalHelp: [],
      aboutUs: [],
    };

    childArticles.forEach(article => {
      const { category } = article;

      if (category === "Getting_Started") articles.gettingStarted.push(article);
      else if (category === "Using_Mailpanion")
        articles.usingMailpanion.push(article);
      else if (category === "Account_Management")
        articles.accountManagement.push(article);
      else if (category === "Data_Security") articles.dataSecurity.push(article);
      else if (category === "Additional_Help")
        articles.additionalHelp.push(article);
      else if (category === "About_Us") articles.aboutUs.push(article);
      else throw new Error(`Unknown article category ${category}`);
    });

    return (
      <Layout>
        <SEO title="Help Center"/>
        <h1 style={{ marginTop: 0 }}>How can we help?</h1>
        <div>
          <h2>Browse by topic</h2>
          <div className={indexStyles.row}>
            <div className={indexStyles.col}>
              <h3>Getting Started</h3>
              {articles.gettingStarted.map(node => {
                return getArticleListing(node);
              })}
            </div>
            <div className={indexStyles.col}>
              <h3>About Us</h3>
              {articles.aboutUs.map(node => {
                return getArticleListing(node);
              })}
            </div>
          </div>
          <div className={indexStyles.row}>
            <div className={indexStyles.col}>
              <h3>Using Mailpanion</h3>
              {articles.usingMailpanion.map(node => {
                return getArticleListing(node);
              })}
            </div>
            <div className={indexStyles.col}>
              <h3>Account Management</h3>
              {articles.accountManagement.map(node => {
                return getArticleListing(node);
              })}
            </div>
          </div>
          <div className={indexStyles.row}>
            <div className={indexStyles.col}>
              <h3>Data Security</h3>
              {articles.dataSecurity.map(node => {
                return getArticleListing(node);
              })}
            </div>
            <div className={indexStyles.col}>
              <h3>Additional Help</h3>
              {articles.additionalHelp.map(node => {
                return getArticleListing(node);
              })}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    cms {
      articles(orderBy: order_DESC, where: { status: PUBLISHED }) {
        title
        slug
        category
      }
    }
  }
`;
